<template>
  <section class="cart-section bg-white box-shaddow20 container px-2 py-4 mb-5">
    <div class="cart-table">
      <div
        class="cart-table-head cart-table-grid text-center border-bottom py-2 px-3"
      >
        <h6 class="text-right fontsize-large weight-bold text-color-444">
          سبد خرید شما
        </h6>
        <h6 class="fontsize-medium text-color-444">قیمت واحد</h6>
        <h6 class="fontsize-medium text-color-444">تعداد</h6>
        <h6 class="fontsize-medium text-color-444">تخفیف</h6>
        <h6 class="fontsize-medium text-color-444">قیمت نهایی</h6>
        <h6 class="fontsize-medium text-color-444">حذف</h6>
      </div>
      <template
        v-if="cart != null && cart.cartItems != null && cart.cartItems != ''"
      >
        <CartItem
          v-for="(cartItem, index) in cart.cartItems"
          :key="index"
          v-model="cart.cartItems[index]"
          :index="index"
          :warning="
            warning != ''
              ? warning.filter((item) => item.cart_id == cartItem.id)[0]
              : null
          "
          :deletedCartIds="
            deletedCartIds != ''
              ? deletedCartIds.filter((item) => item == cartItem.id)[0]
              : null
          "
        />
      </template>
      <template v-else>
        <div class="text-center mt-5">
          <svg
            width="50"
            height="50"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M16.9303 7C16.9621 6.92913 16.977 6.85189 16.9739 6.77432H17C16.8882 4.10591 14.6849 2 12.0049 2C9.325 2 7.12172 4.10591 7.00989 6.77432C6.9967 6.84898 6.9967 6.92535 7.00989 7H6.93171C5.65022 7 4.28034 7.84597 3.88264 10.1201L3.1049 16.3147C2.46858 20.8629 4.81062 22 7.86853 22H16.1585C19.2075 22 21.4789 20.3535 20.9133 16.3147L20.1444 10.1201C19.676 7.90964 18.3503 7 17.0865 7H16.9303ZM15.4932 7C15.4654 6.92794 15.4506 6.85153 15.4497 6.77432C15.4497 4.85682 13.8899 3.30238 11.9657 3.30238C10.0416 3.30238 8.48184 4.85682 8.48184 6.77432C8.49502 6.84898 8.49502 6.92535 8.48184 7H15.4932ZM9.097 12.1486C8.60889 12.1486 8.21321 11.7413 8.21321 11.2389C8.21321 10.7366 8.60889 10.3293 9.097 10.3293C9.5851 10.3293 9.98079 10.7366 9.98079 11.2389C9.98079 11.7413 9.5851 12.1486 9.097 12.1486ZM14.002 11.2389C14.002 11.7413 14.3977 12.1486 14.8858 12.1486C15.3739 12.1486 15.7696 11.7413 15.7696 11.2389C15.7696 10.7366 15.3739 10.3293 14.8858 10.3293C14.3977 10.3293 14.002 10.7366 14.002 11.2389Z"
              fill="#999"
            />
          </svg>
          <h5 class="text-color-999 pt-3 fontsize-large">
            سبد خرید شما خالی می باشد.
          </h5>
        </div>
      </template>
    </div>

    <section
      class="total-price d-md-flex justify-content-between m-3 p-3 bg-color-eai"
    >
      <form
        @submit.prevent="submitCoupon()"
        class="total-price-discount-btn d-flex align-items-center text-color-444 align-self-center bg-none px-3 py-2 mr-md-4 fontsize-small weight-bold"
      >
        <!--          <svg class="ml-2" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
        <!--            <path fill-rule="evenodd" clip-rule="evenodd" d="M20.3991 9.14599L21.1193 9.86599C21.6895 10.426 21.9996 11.186 21.9996 11.986C22.0096 12.786 21.6995 13.547 21.1393 14.116C21.1327 14.1233 21.126 14.1298 21.1193 14.1362C21.116 14.1394 21.1127 14.1427 21.1093 14.146L20.3991 14.856C20.119 15.136 19.9589 15.516 19.9589 15.917V16.946C19.9589 18.606 18.6084 19.957 16.9478 19.957H15.9174C15.5173 19.957 15.1372 20.116 14.8571 20.396L14.1368 21.116C13.5466 21.707 12.7763 21.996 12.006 21.996C11.2357 21.996 10.4655 21.707 9.87525 21.127L9.14498 20.396C8.86488 20.116 8.48474 19.957 8.0846 19.957H7.05423C5.39362 19.957 4.04313 18.606 4.04313 16.946V15.917C4.04313 15.516 3.88308 15.136 3.60298 14.846L2.88271 14.136C1.71229 12.967 1.70229 11.056 2.87271 9.87699L3.60298 9.14599C3.88308 8.86599 4.04313 8.48599 4.04313 8.07599V7.05599C4.04313 5.39599 5.39362 4.04699 7.05423 4.04699H8.0846C8.48474 4.04699 8.86488 3.88599 9.14498 3.60599L9.86524 2.88599C11.0357 1.70699 12.9464 1.70699 14.1268 2.87699L14.8571 3.60599C15.1372 3.88599 15.5173 4.04699 15.9174 4.04699H16.9478C18.6084 4.04699 19.9589 5.39599 19.9589 7.05599V8.08699C19.9589 8.48599 20.119 8.86599 20.3991 9.14599ZM9.42509 15.446C9.66517 15.446 9.88525 15.356 10.0453 15.186L15.1872 10.047C15.5273 9.70699 15.5273 9.14599 15.1872 8.80599C14.8471 8.46699 14.2969 8.46699 13.9567 8.80599L8.81486 13.946C8.47474 14.286 8.47474 14.846 8.81486 15.186C8.97492 15.356 9.195 15.446 9.42509 15.446ZM13.6966 14.566C13.6966 15.056 14.0868 15.446 14.577 15.446C15.0571 15.446 15.4473 15.056 15.4473 14.566C15.4473 14.087 15.0571 13.696 14.577 13.696C14.0868 13.696 13.6966 14.087 13.6966 14.566ZM9.43509 8.55599C9.91526 8.55599 10.3054 8.94599 10.3054 9.42599C10.3054 9.91699 9.91526 10.306 9.43509 10.306C8.95492 10.306 8.55477 9.91699 8.55477 9.42599C8.55477 8.94599 8.95492 8.55599 9.43509 8.55599Z" fill="#f00"/>-->
        <!--          </svg>-->
        <input
          type="text"
          class="discount-input"
          placeholder="کد تخفیف را وارد کنید"
          :disabled="!!discount"
          v-model="couponCode"
        />
        <input
          v-if="!discount"
          :disabled="disabled"
          type="submit"
          class="discount-btn text-color-666 mr-1"
          value="ثبت کد تخفیف"
        />
        <input
          v-else
          type="button"
          @click="removeCoupon"
          class="discount-btn text-color-666 mr-3"
          value="حذف کد تخفیف"
        />
      </form>
      <div class="col-md-5 col-12">
        <div class="text-color-444 d-flex justify-content-between mb-2">
          <span class="fontsize-medium weight-bold"
            >مجموع قیمت های کالا :
          </span>
          <span>
            <span class="fontsize-medium ml-1" v-if="totalPrice != 0">{{
              (totalPrice + totalSaving) | price
            }}</span>
            <span class="fontsize-medium ml-1" v-else>0</span>
            <span class="fontsize-small text-color-999">تومان</span>
          </span>
        </div>
        <div class="text-color-444 d-flex justify-content-between mb-2">
          <span class="fontsize-medium weight-bold"> تخفیف : </span>
          <span>
            <span class="fontsize-medium ml-1">{{ totalSaving | price }}</span>
            <span class="fontsize-small text-color-999">تومان</span>
          </span>
        </div>
        <div
          v-if="discount && discount.discount != 0"
          class="text-color-444 d-flex justify-content-between mb-2"
        >
          <span class="fontsize-medium weight-bold"> تخفیف از کوپن : </span>
          <span>
            <span class="fontsize-medium ml-1">{{
              discount.discount | price
            }}</span>
            <span class="fontsize-small text-color-999">تومان</span>
          </span>
        </div>
        <div class="text-color-444 d-flex justify-content-between mb-2">
          <span class="fontsize-medium weight-bold"> پرداخت نهایی : </span>
          <span class="text-color-theme">
            <span class="fontsize-large ml-1 weight-bold" v-if="total != 0">{{
              total | price
            }}</span>
            <span class="fontsize-medium ml-1" v-else>0</span>
            <span class="fontsize-small">تومان</span>
          </span>
        </div>
      </div>
    </section>
    <div
      class="d-flex justify-content-md-between justify-content-end align-items-baseline px-3 py-2"
    >
      <p class="fontsize-small text-color-666 d-md-block d-none">
        افزودن کالا به سبد خرید به معنی رزرو آن نیست با توجه به محدودیت موجودی
        سبد خود را ثبت و خرید را تکمیل کنید.
      </p>
      <div
        id="next-btn-step-1-container"
        class="position-relative w-25 px-sm-0"
      >
        <button
          id="next-btn-step-1"
          class="continue-buy-process py-2 w-100 fontsize-medium text-center"
          @click="nextPage"
          data-placement="top"
          v-b-tooltip.focus
          :disabled="!isCartChecked || cartIsEmpty || disabled"
        >
          <span v-if="isCartChecked">ادامه فرآیند خرید</span>
          <span v-else-if="isCartChecked === null">در حال بارگزاری</span>
          <span v-else>عملیات به مشکل خورد</span>
        </button>
        <b-tooltip v-if="cartIsEmpty" target="next-btn-step-1-container"
          >{{ cartIsEmpty ? "سبد خرید شما خالی است" : "" }}
        </b-tooltip>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios";
import * as vueMethods from "../../../../libraries/VueMethods";
import CartItem from "@/parts/Front/components/cart/CartItem";
import cart from "@/parts/Front/components/cart/cart";
import { BTooltip, VBTooltip } from "bootstrap-vue";
export default {
  name: "CartStep1",
  components: {
    CartItem,
    BTooltip,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    isCartChecked: Boolean,
    discount: Number,
    couponCodeProp: String,
  },
  data() {
    return {
      disabled: false,
      couponCode: this.couponCodeProp,
      warning: [],
      deletedCartIds: [],
      // تا زمانی که وارنینگ ها لود نشد اجازه نده ادامه بده
      // false = یعنی ارور داد
    };
  },
  watch: {
    // وقتی لاگین کرد دوباره چک بشه سبد خرید
    "$store.state.front.1.homeData.user.login"(isLoggedIn) {
      console.log(this.$store.state.front[1].homeData.user);
      if (isLoggedIn == true) {
        // قرار دادن در وضعیت در حال برگزاری
        this.$emit("cartChecked", null);
        this.$nextTick(() => {
          this.init();
        });
      }
    },
  },
  mounted() {
    this.init(this.cartListStatus);
  },
  methods: {
    async init(data) {
      if (this.$store.getters["front/getLoginStatus"]) {
        let res = data
          ? data
          : await this.$store.dispatch("front/getCustomerCart");
        this.$emit("cartChecked", true);
        this.$emit("shippingsLoaded", res.widgets.shippings);
        this.$emit("gatewaysLoaded", res.widgets.gateways);
        this.$store.commit("front/setUserCart", {
          priority: 2,
          cart: new cart(this.$root, res.carts_light, "server"),
        });
        this.warning = res.messages.warning;
        this.deletedCartIds = res.messages.deleted_cart_ids;
        this.$emit("setReservations", res.reservations);
      } else if (
        !this.$store.getters["front/getLoginStatus"] &&
        this.$store.getters["front/getHomeData"]
      ) {
        this.reloadCart();
      } else {
        this.$emit("cartChecked", true);
      }
    },
    async reloadCart() {
      axios.defaults.headers.common["Authorization"] =
        vueMethods.getCookie("Authorization");
      const response = await axios.get("/all/cart/get", {
        params: { ...cart.getCartRequestQueryString() },
      });
      const data = response.data.data;
      if (data.user.user) {
        this.$store.commit("front/setLoginStatus", data.user);
      } else {
        let initialCarts = cart.getInitialCartForCookieMode(data.cart_request);
        this.$store.commit("front/setUserCart", {
          priority: 0,
          cart: new cart(this, initialCarts, "cookie"),
        });
        this.$emit("cartChecked", true);
      }
      let initialCarts = cart.getInitialCartForCookieMode(data.cart_request);
      this.$store.commit("front/setUserCart", {
        priority: 0,
        cart: new cart(this, initialCarts, "cookie"),
      });
    },
    nextPage() {
      // اگر لاگین نبود مدال نشون بده
      if (!this.isLogin) {
        document.querySelector('button[data-target="#loginModal"]').click();
        return;
      }
      let fixedCarts = this.getFixedCartItems();
      this.$store.commit("front/setUserCart", {
        priority: 3,
        cart: new cart(this.$root, fixedCarts, "server"),
      });
      this.$emit("next-page");
    },
    getFixedCartItems() {
      let carts = this.cart.cartItems;
      return carts.filter((item) => {
        if (this.deletedCartIds.includes(item.id)) {
          return;
        } else {
          return item;
        }
      });
    },
    async submitCoupon() {
      if (!this.isLogin) {
        document.querySelector('button[data-target="#loginModal"]').click();
        return;
      }
      this.disabled = true;
      try {
        const response = await this.$axios.post("customer/coupon/verify", {
          code: this.couponCode,
          total_price: this.totalPrice,
        });
        this.$root.notify("کد تخفیف با موفقیت اعمال شد", "success");
        this.$emit("discountApplied", response.data.data.discount);
        this.$emit("couponApplied", this.couponCode);
      } catch (error) {
        this.$root.notify(error);
      }

      this.disabled = false;
    },
    removeCoupon() {
      this.$emit("discountApplied", null);
      this.$emit("couponApplied", "");
    },
  },
  computed: {
    cartListStatus() {
      return this.$store.getters["front/getCartData"];
    },
    isLogin() {
      return this.$store.getters["front/getLoginStatus"];
    },
    cart() {
      if (
        this.$store.getters["front/getHomeData"] != null &&
        this.$store.getters["front/getHomeData"].user.cart.cartItems != null
      ) {
        return this.$store.getters["front/getHomeData"].user.cart;
      } else {
        return null;
      }
    },
    cartIsEmpty() {
      if (!this.cart) {
        return false;
      }
      let fixedCartItems = this.getFixedCartItems();

      return fixedCartItems.length && fixedCartItems.length === 0;
    },
    total() {
      return this.cart
        ? this.cart.getTotalPrice(this.deletedCartIds) -
            (this.discount ? this.discount.discount : 0)
        : 0;
    },
    totalSaving() {
      return this.cart ? this.cart.getTotalSaving(this.deletedCartIds) : 0;
    },
    totalPrice() {
      return this.cart ? this.cart.getTotalPrice(this.deletedCartIds) : 0;
    },
    homeData() {
      return this.$store.getters["front/getHomeData"];
    },
  },
};
</script>
<style scoped>
.discount-btn {
  background: var(--color-theme3);
  padding: 6px 8px;
  color: white;
}
.discount-input {
  padding: 6px 5px;
}
.discount-input::placeholder {
  color: #8b8b8b;
}
.discount-btn:disabled {
  opacity: 0.7;
  background: #dcdcdc;
}
.discount-btn {
  transition: 200ms all;
}
.discount-btn:hover {
  color: #ffffff !important;
  background: var(--color-theme);
}
</style>
